import lathranLogo from '../../Images/Group 544.png'
import circleImage from '../../Images/circle.png'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
let value;

function Header() {
    const [selectedValue, setSelectedValue] = useState("")

    useEffect(() => {
        handleClick(value)
    }, [selectedValue])

    const handleClick = (selectValue) => {
        value = selectValue;
        setSelectedValue(value);
        // console.log("selected value", selectedValue);
    }

    const toggleNav = (e) => {
        let navcontent = document.getElementById("nav-content");
        navcontent?.classList.toggle("hidden");
    }

    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type === 1) {
                if (window.location.hash.slice(2) === "WhatWeDo") {
                    value = "What We Do?"
                } else if (window.location.hash.slice(2) === "OurTeam") {
                    value = "Our Team"
                } else if (window.location.hash.slice(2) === "WhoWeAre") {
                    value = "Who We Are?"
                } else if (window.location.hash.slice(2) === "LetsTalk") {
                    value = "Lets Talk"
                } else {
                    value = window.location.hash.slice(2)
                }
                handleClick(value)
            } else {
                // console.log("not loadex");
            }
        }
    }, []);

    return (
        <div>
            <header
                className="flex myDIV items-center justify-between flex-wrap py-5 px-9 fixed w-full z-[999] top-0 backdrop-blurr" >
                <div className="flex items-center flex-shrink-0 text-white mr-6" onClick={() => handleClick("")}>
                    <Link className="text-white no-underline hover:text-white hover:no-underline" to="/">
                        <img src={lathranLogo} alt="LathranLogo" className="h-auto" style={{ maxWidth: "180px" }}></img>
                    </Link>
                </div>

                <div className="block lg:hidden backdrop-blurr">
                    <button id="nav-toggle" onClick={toggleNav}
                        className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-[#132B3D] hover:border-[#132B3D]">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>

                <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden pt-6 lg:pt-0 backdrop-blurr" id="nav-content">
                    <ul className="list-reset lg:flex justify-center flex-1 items-center">
                        <Link to="/WhoWeAre" className='cursor-pointer flex justify-center h-10 items-center' onClick={() => handleClick("Who We Are?")}>
                            <li className="mr-3 flex flex-col justify-center items-center" onClick={() => handleClick("Who We Are?")}>
                                <p className="linkk inline-block no-underline text-[#132B3D] py-2 px-4">Who We Are?</p>
                                <img src={circleImage} alt="circleImage" className={selectedValue === "Who We Are?" ? "" : "hidden"}></img>
                            </li>
                        </Link>

                        <Link to="/OurTeam" className='cursor-pointer flex justify-center h-10 items-center ' onClick={() => handleClick("Our Team")}>
                            <li className="mr-3 flex flex-col justify-center items-center" onClick={() => handleClick("Our Team")}>
                                <p className="linkk inline-block no-underline text-[#132B3D] py-2 px-4 "
                                >Our Team</p>
                                <img src={circleImage} alt="circleImage" className={selectedValue === "Our Team" ? "" : "hidden"}></img>
                            </li>
                        </Link>

                        <Link to="/WhatWeDo" className='cursor-pointer flex justify-center h-10 items-center' onClick={() => handleClick("What We Do?")}>
                            <li className="mr-3 flex flex-col justify-center items-center" onClick={() => handleClick("What We Do?")}>
                                <p className="linkk inline-block no-underline text-[#132B3D] py-2 px-4 ">What We Do?</p>
                                <img src={circleImage} alt="circleImage" className={selectedValue === "What We Do?" ? "show" : "hidden"}></img>
                            </li>
                        </Link>

                        <Link to="/POD" className='cursor-pointer flex justify-center h-10 items-center' onClick={() => handleClick("POD")}>
                            <li className="mr-3 flex flex-col justify-center items-center" onClick={() => handleClick("POD")}>
                                <p className="linkk inline-block no-underline text-[#132B3D] py-2 px-4 "
                                >POD</p>
                                <img src={circleImage}  alt="circleImage" className={selectedValue === "POD" ? "show" : "hidden"}></img>
                            </li>
                        </Link>

                        <Link to="/Products" className='cursor-pointer flex justify-center h-10 items-center' onClick={() => handleClick("Products")}>
                            <li className="mr-3 flex flex-col justify-center items-center" onClick={() => handleClick("Products")}>
                                <p className="linkk inline-block no-underline text-[#132B3D] py-2 px-4 "
                                >Products</p>
                                <img src={circleImage} alt="circleImage" className={selectedValue === "Products" ? "show" : "hidden"}></img>
                            </li>
                        </Link>


                        <Link to="/Career" className='cursor-pointer flex justify-center h-10 items-center' onClick={() => handleClick("Career")}>
                            <li className="mr-3 flex flex-col justify-center items-center" onClick={() => handleClick("Career")}>
                                <p className="linkk inline-block no-underline text-[#132B3D] py-2 px-4 "
                                >Career</p>
                                <img src={circleImage} alt="circleImage" className={selectedValue === "Career" ? "show" : "hidden"}></img>
                            </li>
                        </Link>
                    </ul>

                    <Link to="/LetsTalk" onClick={() => handleClick("Lets Talk")}>
                        <p className={`flex justify-center items-center border border-[#AB322F] px-7 rounded-3xl h-11 ${selectedValue === "Lets Talk" ? "text-white bg-[#AB322F]" : "text-[#AB322F] hover:bg-[#f7d2d2]"}`}>Let's
                            Talk
                        </p>
                    </Link>
                </div>
            </header >
        </div >
    );
}

export default Header;