import ellipse from "../../Images/ellipse.svg";
import vector from "../../Images/Vector.png";
import bulb from "../../Images/Group 325.png";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useState, useRef } from "react";
import swal from "sweetalert";
import { baseURL } from "../../URL";
import emailjs from '@emailjs/browser';
import { log } from "console";

function WhatsOnYourMind() {
  const formRef = useRef<HTMLFormElement | null>(null);  
  const [value, setValue] = useState("");
  const [firstNameValue, setfirstNameValue] = useState("");
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");

  const handleChangeTextarea = (event) => {
    const { value } = event.target;
    setText(value);
  };  

  const handleSubmit = (e) => {

    e.preventDefault();
    const trimmedName = firstNameValue.trim();
    const trimmedMessage = text.trim();

    // Check if any field is empty or contains only spaces
    if (trimmedName === "" || trimmedMessage === "") {
      swal("Please fill in all the fields.", "", "warning");
    } else {
      var dataa = $("#Form").serialize();
      const form = $(e.target);
      $.ajax({
        url: form.attr("action"),
        method: "POST",
        data: dataa,
        success: function (result) {
          console.log('====================================');
          console.log(result, "Resulkttt");
          console.log('====================================');
          swal(
            "Thank You For Contacting us!",
            "We have received your Message and will respond to you as soon as possibleeee.",
            "success"
          );

          $("#Form")[0].reset();
          setValue("");
          setText("");
          setfirstNameValue("");
        },
        error: function (err) {
          console.log("Error", err);
        },
      });
    }
  };
  const handlePhoneNumberChange = (event) => {
    const maxLength = 15; // Maximum number of digits allowed
    const inputValue = event.target.value;

    // Remove non-numeric characters except "+"
    const sanitizedValue = inputValue.replace(/[^0-9+]/g, "");

    if (sanitizedValue.length <= maxLength) {
      setValue(sanitizedValue);
    }
  };    

  const serviceId = "service_b2340r7"
  const templateId = "template_gj7vlwn"
  const publicKey = "Jssii43C4o90icfIz"
  

  const sendEmail = (e) => {
    console.log("firstNameValue", firstNameValue);
    console.log("text", text);
    console.log("email", email);
    console.log("phone number value", value);
    
    e.preventDefault();
    if (!formRef.current){
      console.log("form me error");
      
      return
    }
    console.log("formRef: ", formRef)
    console.log("formRef: ", formRef.current)
    const formFields = formRef.current?.querySelectorAll('input, textarea');
    console.log("formFields: ",formFields);    
    
    
    const messageContent = `
<strong>Full Name:</strong> ${firstNameValue}<br>
<strong>Email Address:</strong> ${email}<br>
<strong>Phone Number:</strong> ${value}<br>
<strong>Message:</strong> ${text}
`;
    
    try {
      if (firstNameValue === "" || email === "" || value=== "" || text=== ""){
        alert("Please fill al fields")
        return
      }
      emailjs.send(serviceId, templateId, {        
        message: `Full Name : ${firstNameValue},\n Email Address : ${email},\n Phone Number : ${value},\n Message : ${text}`,        
        reply_to: "admin@lathran.com",
        }, publicKey)
        .then(response => console.log('SUCCESS!', response))
        .catch(error => console.log('error sending email: ', error));
    } catch (error) {
      console.log("Error in email sending mechanism: ", error);
    }
    
      
  };

  // const form = useRef<HTMLFormElement>(null); // Define a form reference
  
  // const sendEmail = (e: React.FormEvent) => {    
    
  //   e.preventDefault();
  //   console.log("ali");
          
  //   if (formRef.current) {
  //     emailjs
  //       .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formRef.current, {
  //         publicKey: 'YOUR_PUBLIC_KEY',
  //       })
  //       .then(
  //         () => {
  //           console.log('SUCCESS!');
  //         },
  //         (error) => {
  //           console.log('FAILED...', error.text);
  //         },
  //       );
  //   } else {
  //     console.error('Form reference is null.'); // Handle the case when form reference is null
  //   }
  // };
  
  return (
    <>
      <form
      ref={formRef}
        action={`${baseURL}/services/postForm.php`}
        // action="http://localhost/ls-react-live/services/postForm.php"
        className="md:w-full sm:w-full w-full lg:w-3/5"
        id="Form"
        // onSubmit={handleSubmit}
        onSubmit={(e)=>{
          handleSubmit(e)
          sendEmail(e)
        }}
      >
        <div className="  flex flex-col gap-y-5">
          <h1 className="text-[#132B3D] font-bold text-2xl">
            What’s On Your Mind?
          </h1>
          {/* <input type="tel" id="field_phone" name="field_phone" className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b-2 border-[#808080] outline-none"></input> */}

          <div className="py-2">
            <div className="relative group">
              <input
                type="text"
                id="FullName"
                required
                name="name"
                maxLength={25}
                onChange={(e) => {
                  let value = e.target.value;
                  const regex = /^[a-zA-Z\s]*$/;
                  if (regex.test(value)) {
                    setfirstNameValue(value);
                  }
                }}
                value={firstNameValue}
                autoComplete="off"
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b-2 border-[#808080] outline-none"
              ></input>
              <label
                htmlFor="FullName"
                className=" text-[#808080]  transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Full Name *
              </label>
            </div>
          </div>

          <div className="py-2">
            <div className=" relative group">
              <input
                type="email"
                id="emailAddress"
                required
                name="email"
                maxLength={255}
                autoComplete="off"
                onChange={(e)=>setEmail(e.target.value)}
                value={email}
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b-2 border-[#808080] outline-none"
              ></input>
              <label className=" text-[#808080] transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 peer-valid:-translate-y-full group-focus-within:-translate-y-full  group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#808080]">
                Email Address *
              </label>
            </div>
          </div>

          <div className="py-2">
            <div className=" relative group">
              <input
                type="number"
                id="phoneNumber"
                required
                name="phoneNo"
                onChange={handlePhoneNumberChange}
                value={value}
                autoComplete="off"
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b-2 border-[#808080] outline-none"
              ></input>
              <label
                htmlFor="phoneNumber"
                className="text-[#808080] text-sm  transform transition-all absolute top-0 left-0 h-full flex items-center pl-2  group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Phone Number *
              </label>
            </div>
          </div>

          <div className="py-2">
            <div className="relative group">
              <textarea
                // type="text"
                id="message"
                required
                name="message"
                maxLength={255}
                autoComplete="off"
                rows={5}
                cols={6}
                onChange={handleChangeTextarea}
                value={text}
                className="resize-none text-[#132B3D] w-full font-bold text-xl peer border-b-2 border-[#808080] outline-none"
              ></textarea>
              <label
                htmlFor="message"
                className="text-[#808080]  transform transition-all absolute top-0 left-0 h-full flex items-start pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/6 peer-valid:h-1/6 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Message *
              </label>
              <p className="text-[#132B3D] flex justify-end">
                {text.length} / 255
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-col sm:flex-col lg:flex-row md:gap-y-8 sm:gap-y-8 gap-y-8 lg:gap-y-0 justify-between lg:items-center md:items-start sm:items-start items-start mt-4">
            <div className="flex justify-center items-center hover:cursor-pointer">
              <div className="flex items-center">
                <img src={ellipse} className="w-14 h-14" alt="Ellipse"></img>
                <input
                  className="-translate-x-5 p-1 text-[#132B3D] hover:cursor-pointer"
                  value="Submit"
                  type="submit"
                  name="submit"
                ></input>
                <img
                  src={vector}
                  className="-translate-x-3 w-2 h-4 arrow"
                  alt="Vector"
                ></img>
              </div>
            </div>

            <Link to="/LetsBuild" className="flex gap-x-2">
              <img src={bulb} className="w-5 h-5" alt="Bulb"></img>
              <p className="text-sm text-[#AB322F] font-bold">
                Let’s Build Something Together
              </p>
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}

export default WhatsOnYourMind;
