import { useEffect, useState } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import '../../App.css'
import ellipse from '../../Images/ellipse.svg'
import Vector from '../../Images/Vector.png'
import previousArrow from '../../Images/Group 1000011012.svg'
import nextArrow from '../../Images/Group 1000011011.svg'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ProductDetailsData } from './ProductData'
import ShareYourIdeas from '../GlobalComponent/ShareYourIdeaas';

function ProductDetailsPaqe() {
    // let dataToShow;
    const location = useLocation()
    const { from } = location.state
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [dataToShow, setDataToShow] = useState<{ heading: string; logo: string; subheading: string; description: string; languages: string[]; images: { src: string }[]; } | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const item = ProductDetailsData.find(item => item.heading === from.heading);
        if (item) {
            setDataToShow(item);
            setCurrentItemIndex(ProductDetailsData.indexOf(item));
        }
    }, [from]);

    const handleNext = () => {
        setCurrentItemIndex(prevIndex => (prevIndex + 1) % ProductDetailsData.length);
    };

    const handlePrevious = () => {
        setCurrentItemIndex(prevIndex => (prevIndex - 1 + ProductDetailsData.length) % ProductDetailsData.length);
    };

    useEffect(() => {
        const item = ProductDetailsData[currentItemIndex];
        setDataToShow(item);
        window.scrollTo(0, 0);
    }, [currentItemIndex]);

    return (
        <div>
            {dataToShow &&
                <div className="flex flex-col lg:flex-row md:flex-col sm:flex-col lg:px-36 px-10 md:px-10 sm:px-10 mb-8 gap-x-12 mt-[120px]">
                    <div className=" flex flex-col basis-1/2 gap-y-8">
                        <p className="text-[#132B3D] text-lg">{dataToShow.heading}</p>
                        <h1 className="text-[#132B3D] text-4xl font-bold ">{dataToShow.subheading}</h1>
                        <img src={dataToShow.logo} className='h-auto w-fit'></img>
                        <p className="arial text-sm text-[#132B3D]">{dataToShow.description}</p>
                        <div className="flex flex-wrap gap-x-5 gap-y-3">
                            {dataToShow.languages.map((language, index) => (
                                <span
                                    key={index}
                                    className="flex justify-center items-center bg-[#132B3D] rounded-full arial text-xs h-7 px-4 text-white"
                                >
                                    {language}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="basis-1/2 mt-10 lg:mt-0 md:mt-10 sm:mt-10 flex justify-center items-center">
                        <div className='my-carousel'>
                            <Carousel playIcon={false} images={dataToShow.images} style={{ width: 400 }} isAutoPlaying={true} autoPlayInterval={2000} isLoop={true} hasSizeButtonAtMax={false} pauseIcon={false} rightIcon={false} leftIcon={false} maxIcon={false} />
                        </div>
                    </div>
                </div>
            }
            <div className='flex justify-between lg:px-36 px-10 md:px-10 sm:px-10 mb-10'>
                <div className='flex gap-x-2 justify-center items-center' onClick={handlePrevious}>
                    <img src={previousArrow}></img>
                    <div className='text-[#AB322F] font-semibold cursor-pointer'>Previous</div>
                </div>
                <div className='flex gap-x-2 justify-center items-center cursor-pointer' onClick={handleNext}>
                    <div className='text-[#AB322F] font-semibold'>Next</div>
                    <img src={nextArrow}></img>
                </div>
            </div>
            <ShareYourIdeas paragraphText={"Let’s Build Something Together"} />
        </div>
    );
}

export default ProductDetailsPaqe;