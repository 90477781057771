import ellipse from "../../Images/ellipse.svg";
import vector from "../../Images/Vector.png";
import "../../App.css";
import $ from "jquery";
import swal from "sweetalert";
import { useState, useRef } from "react";
import { baseURL } from "../../URL";
import emailjs from '@emailjs/browser';

function Form() {
  // const formRef = useRef<HTMLFormElement | null>(null);  
  const [email, setEmail] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [companyInput, setcompanyInput] = useState("");
  const [value, setValue] = useState("");
  const [text, setText] = useState("");
  const [firstNameValue, setfirstNameValue] = useState("");
  const [companyName, setcompanyName] = useState("");

  const serviceId = "service_b2340r7"
  const templateId = "template_gj7vlwn"
  const publicKey = "Jssii43C4o90icfIz"

  const handleChange = (e) => {
    setCountryInput(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setcompanyInput(e.target.value);
  };

  const handleCompanyClick = (e) => {
    e.target.setAttribute("value", companyInput);
    setcompanyInput(companyInput);
  };
  const handleChangeTextarea = (event) => {
    const { value } = event.target;
    setText(value);
  };

  const handlePhoneNumberChange = (event) => {
    const maxLength = 15; // Maximum number of digits allowed
    const inputValue = event.target.value;

    // Remove non-numeric characters except "+"
    const sanitizedValue = inputValue.replace(/[^0-9+]/g, "");

    if (sanitizedValue.length <= maxLength) {
      setValue(sanitizedValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedName = firstNameValue.trim();
    const trimmedMessage = text.trim();
    const trimmedvalue = value.trim();

    if (trimmedName === "" || trimmedMessage === "" || trimmedvalue === "") {
      swal("Please fill in all the fields.", "", "warning");
    } else {
      // try {        
      //   emailjs.send(serviceId, templateId, {          
      //     message: `${firstNameValue}, ${countryInput}, ${value}, ${text}, ${companyName}, ${companyInput}`,
      //     reply_to: "admin@lathran.com",
      //     }, publicKey)
      //     .then(response => console.log('SUCCESS!', response))
      //     .catch(error => console.log('error sending email: ', error));
      // } catch (error) {
      //   console.log("Error in email sending mechanism: ", error);
      // }
      var dataa = $("#Form").serialize();
     
      const form = $(e.target);
      $.ajax({
        url: form.attr("action"),
        method: "POST",
        data: dataa,
        success: function (result) {
          swal(
            "Thank You For Contacting us!",
            "We have received your Message and will respond to you as soon as possible.",
            "success"
          );
          setCountryInput("");
          setcompanyInput("");
          setfirstNameValue("");
          setValue("");
          setcompanyName("");
          $("#Form")[0].reset();
        },
        error: function (err) {
          console.log("Error", err);
        },
      });
    }
  };

  const sendEmail = (e) => {
    console.log("firstNameValue", firstNameValue);
    console.log("text", text);
    console.log("email", email);
    console.log("phone number value", value);
    console.log("country: ", countryInput);    
    console.log("companyName: ", companyName);
    console.log("companyInput: ", companyInput);    
    
    e.preventDefault();
    // if (!formRef.current){
    //   console.log("form me error");
      
    //   return
    // }
    // console.log("formRef: ", formRef)
    // console.log("formRef: ", formRef.current)
    // const formFields = formRef.current?.querySelectorAll('input, textarea');
    // console.log("formFields: ",formFields);    
        
    
    try {
      if (firstNameValue === "" || email === "" || value=== "" || text=== "" || countryInput=== "" || companyName=== "" || companyInput=== ""){
        alert("Please fill al fields")
        return
      }
      emailjs.send(serviceId, templateId, {
        // message: "ali, alibinafaq98@gmail.com, +923353089247, my name is ali",
        message: `Full Name : ${firstNameValue},\n Business Email Address:  ${email},\n Phone Number : ${value},\n Country / Region : ${countryInput},\n Company Name : ${companyName},\n Industry : ${companyInput},\n Tell Us About Your Idea : ${text}`,
        reply_to: "admin@lathran.com",
        }, publicKey)
        .then(response => console.log('SUCCESS!', response))
        .catch(error => console.log('error sending email: ', error));
    } catch (error) {
      console.log("Error in email sending mechanism: ", error);
    }
    
      
  };

  return (
    <form
      action={`${baseURL}/services/postletsbuildForm.php`}
      // action="http://localhost/ls-website/ls-react-live/services/postletsbuildForm.php"
      className="w-full"
      id="Form"
      // onSubmit={handleSubmit}
      onSubmit={(e)=>{
        handleSubmit(e)
        sendEmail(e)
      }}
    >
      <div className="flex flex-col lg:flex-row md:flex-col sm:flex-col gap-x-24 justify-between">
        <div className="lg:basis-[47%] md:basis-full sm:basis-full basis-full">
          <div className="py-5">
            <div className="relative group">
              <input
                type="text"
                id="FullName"
                required
                name="name"
                maxLength={25}
                onChange={(e) => {
                  let value = e.target.value;
                  const regex = /^[a-zA-Z\s]*$/;
                  if (regex.test(value)) {
                    setfirstNameValue(value);
                  }
                }}
                value={firstNameValue}
                autoComplete="off"
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b border-[#808080] outline-none"
              ></input>
              <label
                htmlFor="FullName"
                className=" text-[#808080]  transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Full Name *
              </label>
            </div>
          </div>

          <div className="py-5">
            <div className=" relative group">
              <input
                type="email"
                id="emailAddress"
                required
                name="email"
                maxLength={255}
                onChange={(e)=>setEmail(e.target.value)}
                value={email}
                autoComplete="off"
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b border-[#808080] outline-none"
              ></input>
              {/* <label
                htmlFor="emailAddress"
                className="text-[#808080] text-sm  transform transition-all absolute top-0 left-0 h-full flex items-center pl-2  group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Business Email Address
              </label> */}

              <label className=" text-[#808080] transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 peer-valid:-translate-y-full group-focus-within:-translate-y-full  group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#808080]">
                Business Email Address *
              </label>
            </div>
          </div>

          <div className="py-5">
            <div className=" relative group">
              <input
                type="number"
                id="phoneNumber"
                required
                name="phoneNo"
                onChange={handlePhoneNumberChange}
                value={value}
                autoComplete="off"
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b border-[#808080] outline-none"
              ></input>
              <label
                htmlFor="phoneNumber"
                className="text-[#808080] text-sm  transform transition-all absolute top-0 left-0 h-full flex items-center pl-2  group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Phone Number *
              </label>
            </div>
          </div>
        </div>

        <div className="basis-[47%]">
          <div className="py-5">
            <div className="relative group ">
              <select
                id="Country"
                name="country"
                required
                className=" text-[#132B3D] w-full h-10 font-bold text-xl  border-b border-[#808080] outline-none peer"
                value={countryInput}
                onChange={handleChange}
              >
                {/* onClick={this.setAttribute('value', this.value)}   */}
                <option value=""></option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Aland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire, Sint Eustatius and Saba">
                  Caribbean Netherlands
                </option>
                <option value="Bosnia and Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">
                  Falkland Islands (Islas Malvinas)
                </option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">
                  French Southern Territories
                </option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">
                  Heard & McDonald Islands
                </option>
                <option value="Holy See (Vatican City State)">
                  Vatican City
                </option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">
                  North Korea
                </option>
                <option value="Korea, Republic of">South Korea</option>
                <option value="Kosovo">Kosovo</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, the Former Yugoslav Republic of">
                  North Macedonia
                </option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">
                  Micronesia
                </option>
                <option value="Serbia">Serbia</option>
                <option value="Serbia and Montenegro">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Sint Maarten">Sint Maarten</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and the South Sandwich Islands">
                  South Georgia & South Sandwich Islands
                </option>
                <option value="South Sudan">South Sudan</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">
                  Svalbard & Jan Mayen
                </option>
                <option value="Swaziland">Eswatini</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syria</option>
                <option value="Taiwan, Province of China">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-Leste">Timor-Leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">
                  Turks & Caicos Islands
                </option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">
                  U.S. Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Vietnam</option>
                <option value="Virgin Islands, British">
                  British Virgin Islands
                </option>
                <option value="Virgin Islands, U.s.">
                  U.S. Virgin Islands
                </option>
                <option value="Wallis and Futuna">Wallis & Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <label
                id="Country-label"
                className=" text-[#808080] transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 peer-valid:-translate-y-full group-focus-within:-translate-y-full  group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#808080]"
              >
                Country / Region *
              </label>
            </div>
          </div>

          <div className="py-5">
            <div className="relative group ">
              <input
                type="text"
                id="companyName"
                required
                name="companyName"
                maxLength={25}
                onChange={(e) => {
                  let value = e.target.value;
                  const regex = /^[a-zA-Z\s]*$/;
                  if (regex.test(value)) {
                    setcompanyName(value);
                  }
                }}
                value={companyName}
                autoComplete="off"
                className="text-[#132B3D] w-full h-10 font-bold text-xl peer border-b border-[#808080] outline-none"
              ></input>
              <label
                htmlFor="Company"
                className=" text-[#808080] transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
              >
                Company Name *
              </label>
            </div>
          </div>

          <div className="py-5">
            <div className="relative group ">
              <select
                id="Industry"
                name="companyIndustry"
                required
                className="text-[#132B3D] w-full h-10 font-bold text-xl  border-b border-[#808080] outline-none peer"
                value={companyInput}
                onChange={handleCompanyChange}
                onClick={handleCompanyClick}
              >
                {/* onclick="this.setAttribute('value', this.value);"  */}
                <option value=""></option>
                <option value="Accounting">Accounting</option>
                <option value="Apparel/Fashion">Apparel/Fashion</option>
                <option value="Architecture/Planning">
                  Architecture/Planning
                </option>
                <option value="Arts/Crafts">Arts/Crafts</option>
                <option value="Automotive">Automotive</option>
                <option value="Information Technology/IT">
                  Information Technology/IT
                </option>
                <option value="Management Consulting">
                  Management Consulting
                </option>
                <option value="Textiles">Textiles</option>
                <option value="Restaurants">Restaurants</option>
                <option value="Real Estate/Mortgage">
                  Real Estate/Mortgage
                </option>
                <option value="Medical Equipment">Medical Equipment</option>
                <option value="Medical Practice">Medical Practice</option>
                <option value="Marketing/Advertising/Sales">
                  Marketing/Advertising/Sales
                </option>
              </select>
              <label className=" text-[#808080] transform transition-all absolute top-0 left-0 h-full flex items-center pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 peer-valid:-translate-y-full group-focus-within:-translate-y-full  group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#808080]">
                Industry *
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="relative group ">
          <textarea
            id="Idea"
            required
            name="idea"
            autoComplete="off"
            rows={5}
            cols={6}
            maxLength={255}
            onChange={handleChangeTextarea}
            className="resize-none text-[#132B3D] w-full  font-bold text-xl peer border-b border-[#808080] outline-none"
          ></textarea>
          <label
            htmlFor="Idea"
            className=" text-[#808080]  transform transition-all absolute top-0 left-0 h-full flex items-start pl-2 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/6 peer-valid:h-1/6 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0 group-focus-within:text-[#132B3D]"
          >
            Tell Us About Your Idea... *
          </label>
          <p className="text-[#132B3D] flex justify-end">{text.length} / 255</p>
        </div>
      </div>

      {/* <!-- Submit Button  --> */}
      <div className="flex justify-center items-center my-8 hover:cursor-pointer">
        <div className="flex items-center">
          <img src={ellipse} className="w-14 h-14" alt="ellipse"></img>
          <input
            type="submit"
            name="submit"
            value="Submit"
            className="-translate-x-5 p-1 text-[#132B3D] hover:cursor-pointer"
          />
          <img
            src={vector}
            className="-translate-x-3 w-2 h-4 arrow"
            alt="Arrow"
          ></img>
        </div>
      </div>
    </form>
  );
}

export default Form;
