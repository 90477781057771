import ProductDetailsPaqe from "../Components/Products/ProductDetailsPage";
import Header from "../Components/GlobalComponent/Header";
import Footer from "../Components/GlobalComponent/Footer";

function ProductDetailsPageMain() {
    return (
        <div className="h-screen flex flex-col">
            <Header />
            <ProductDetailsPaqe />
            <Footer />
        </div>
    );
}

export default ProductDetailsPageMain;