import { ProductData } from "./ProductData";
import arrowImage from '../../Images/arrow-up-right.png'
import { Link } from 'react-router-dom'

function ProductList() {
    const render = ProductData.map((item, index) =>
        <Link to="/ProductDetails" key={index} state={{ from: item }}
            className="flex flex-col gap-y-2 items-center lg:basis-[47%] md:basis-full sm:basis-full basis-full"
        >
            <img src={item.image} alt="Arrow" className="w-full"></img>
            <div className="flex justify-between w-full">
                <h2 className="text-[#132B3D] arial text-sm text-left">
                    {item.heading}</h2>
                <img src={arrowImage} alt="Arrow" ></img>
            </div>
            <p className="text-[#132B3D] w-full text-left font-bold lg:text-base text-sm sm:text-sm md:text-sm">
                {item.detail}
            </p>
        </Link>
    );

    return (
        <div className="flex justify-between flex-wrap gap-y-14 lg:py-20 py-10 md:py-10 sm:py-10 lg:px-36 md:px-10 sm:px-10 px-10">{render}</div>
    );
}

export default ProductList;