import EzPaniImage from '../../Images/Group 664.png'
import Scheduler from '../../Images/Group 522.png'
import OrderManagement from '../../Images/Group 523.png'
import influencerMarketing from '../../Images/Group 666.png'
import PaymentGateway from '../../Images/Group 668.png'
import SurveyManager from '../../Images/Group 669.png'
import greetrImage from "../../Images/Greetr.png"
import propertyManagementImage from "../../Images/Property.png"
import AdvancedClinicalTrialsImage from "../../Images/ClinicalTrials.png"
import CollaborationApplicationImage from "../../Images/Collaboration.png"
import OperationsImage from "../../Images/Operations.png"

//Greetr
import greetr1 from "../../Images/Group 1000011007.png"
import greetr2 from "../../Images/Group 1000011008.png"
import greetr3 from "../../Images/Group 1000011009.png"
import greetr4 from "../../Images/Group 1000011010.png"
import greetrLogo from "../../Images/Group 1000011014.png"

//Property Valuation
import property1 from "../../Images/Group 1000010997.png"
import property2 from "../../Images/Group 1000010998.png"
import property3 from "../../Images//Group 1000010999.png"
import property4 from "../../Images/Group 1000011000.png"
import foxyLogo from "../../Images/Foxy_ Logo - Full Colour - Solid - White 1.png"

//collab
import collab1 from "../../Images/Group 1000010993.png"
import collab2 from "../../Images/Group 1000010994.png"
import collab3 from "../../Images/Group 1000010995.png"
import collab4 from "../../Images/Group 1000010996.png"
import collabLogo from "../../Images/Group 10.png"

//Epro
import epro1 from "../../Images/Group 1000010989.png"
import epro2 from "../../Images/Group 1000010990.png"
import epro3 from "../../Images/Group 1000010991.png"
import epro4 from "../../Images/Group 1000010992.png"
import eproLogo from "../../Images/admin_logo 1.png"

//LathranSuite
import lathranSuite1 from "../../Images/Group 1000010980.png"
import lathransuite2 from "../../Images/Group 1000010981.png"
import lathransuite3 from "../../Images/Group 1000010982.png"
import lathransuite4 from "../../Images/Group 1000010983.png"
import lathransuiteLogo from "../../Images/Group 1000011011.png"

// Ezpani
import EzpaniImage1 from '../../Images/Group 579.png'
import EzpaniImage2 from '../../Images/Group 580.png'
import EzpaniImage3 from '../../Images/Group 581.png'
import EzpaniImage4 from '../../Images/Group 582.png'
import EzpaniLogo from "../../Images/Group 2683.png"

// Scheduler
import SchedulerImage1 from '../../Images/Group 555.png'
import SchedulerImage2 from '../../Images/Group 556.png'
import SchedulerImage3 from '../../Images/Group 557.png'
import SchedulerImage4 from '../../Images/Group 558.png'
import SchedulerLogo from "../../Images/Group 1000011006.png"

//MOMS
import MOMSImage1 from '../../Images/Group 559.png'
import MOMSImage2 from '../../Images/Group 560.png'
import MOMSImage3 from '../../Images/Group 561.png'
import MOMSImage4 from '../../Images/Group 562.png'
import MomLogo from "../../Images/Moms logo (1).png"

//Influencer
import InfluencerImage1 from '../../Images/Group 563.png'
import InfluencerImage2 from '../../Images/Group 564.png'
import InfluencerImage3 from '../../Images/Group 565.png'
import InfluencerImage4 from '../../Images/Group 566.png'
import Flonzo from "../../Images/flonzologo.png"

//Payment Gateway
import PaymentGatewayImage1 from '../../Images/Group 551.png'
import PaymentGatewayImage2 from '../../Images/Group 552.png'
import PaymentGatewayImage3 from '../../Images/Group 553.png'
import PaymentGatewayImage4 from '../../Images/Group 554.png'
import eztrialpaylogo from "../../Images/eztrialpaylogo.png"

//Survey Management
import SurveyManager1 from '../../Images/Group 547.png'
import SurveyManager2 from '../../Images/Group 548.png'
import SurveyManager3 from '../../Images/Group 549.png'
import SurveyManager4 from '../../Images/Group 550.png'
import surveyMananegemnt from "../../Images/esurveylogo.png"

export const ProductData: { image: string; heading: string, detail: string }[] = [
    { image: greetrImage, heading: "Candidate Engagement", detail: "Revolutionize Recruitment With Greetr's Platform, Boosting Candidate Engagement And Streamlining Hiring Manager Tasks." },
    { image: propertyManagementImage, heading: "Property Valuations", detail: "Transform Real Estate Valuations With FoxyAI's AI-driven Tools For Instant, Precise Property Insights." },
    { image: AdvancedClinicalTrialsImage, heading: "Advanced Clinical Trials", detail: "Streamline clinical trials with EvolutionRX's digital platform, enhancing efficiency and participant engagement." },
    { image: CollaborationApplicationImage, heading: "Collaboration Application", detail: "Stay connected and drive teamwork forward with ultimate mobile tool." },
    { image: OperationsImage, heading: "Operations Platform", detail: "Centralize and streamline B2B operations with all-in-one integrated operations platform." },
    { image: OrderManagement, heading: "Order Management System", detail: "OMS-Digital order booking Platform for micro businesses." },
    { image: SurveyManager, heading: "Survey Manager", detail: "eSurvey gives actionable insight and fresh perspectives." },
    { image: influencerMarketing, heading: "Influencer Marketing Platform", detail: "Enabling brands match their product campaign with the right influencers through AI based logical matchmaking." },
    { image: Scheduler, heading: "Scheduler", detail: "Schedule appointments dynamically and set reminders." },
    { image: PaymentGateway, heading: "Payment Gateway", detail: "Make payments with a piece of mind through our secure and customizable payment gateway solution" },
    { image: EzPaniImage, heading: "Supply Chain Solution", detail: "From vendor management to warehousing, inventory management, oder booking to last mile delivery,all-in-one" },
]

export const ProductDetailsData: { heading: string; logo: string, subheading: string, description: string, languages: Array<string>, images: [{ src: string }, { src: string }, { src: string }, { src: string }] }[] = [
    {
        heading: "Candidate Engagement",
        logo: greetrLogo,
        subheading: "Revolutionize Recruitment With Greetr's Platform, Boosting Candidate Engagement And Streamlining Hiring Manager Tasks",
        description: "By partnering with Greetr, we've crafted a candidate engagement platform that revolutionizes recruitment—enhancing interactions and optimizing hiring manager workflows.",
        languages: ["Chrome Extension", "Merge Integration", "AWS", "MERN", "Calendly Integration"],
        images: [{ src: greetr1 }, { src: greetr2 }, { src: greetr3 }, { src: greetr4 }]
    }, {
        heading: "Property Valuations",
        logo: foxyLogo,
        subheading: "Transform Real Estate Valuations with FoxyAI's AI-driven Tools For Instant, Precise Property Insights",
        description: "We helped FoxyAI shape the future of real estate technology, delivering a solution where advanced AI meets visual data to transform property analysis. Our collaboration has spawned a tool that's indispensable for professionals seeking rapid, precise market assessments. With FoxyAI, we're not just predicting the future; we're building it.",
        languages: ["Machine Learning", "Computer Vision", "API", "MERN"],
        images: [{ src: property1 }, { src: property2 }, { src: property3 }, { src: property4 }]
    }, {
        heading: "Advanced Clinical Trials",
        logo: eproLogo,
        subheading: "Streamline Clinical Trials With EvolutionRX's Digital Platform, Enhancing Efficiency And Participant Engagement",
        description: "Revolutionize your clinical trials with EvolutionRX, leveraging a platform that elevates participant engagement and optimizes trial efficiency. Navigate the landscape of decentralized trials with a system designed for the complexities of modern research. ",
        languages: ["PHP-Laravel", "React JS", "MySQL", "SSO "],
        images: [{ src: epro1 }, { src: epro2 }, { src: epro3 }, { src: epro4 }]
    }, {
        heading: "Collaboration Application",
        logo: collabLogo,
        subheading: "Stay Connected And Drive Teamwork forward With Ultimate Mobile Tool",
        description: "Collab provides real-time messaging to keep you informed and engaged, wherever you may be. This app acts as your personal mobile command center; your projects, tasks, and discussions are efficiently organized into clear, focused channels. ",
        languages: ["Swift", "Core Data", "Kotlin", "Siri", "Firebase  "],
        images: [{ src: collab1 }, { src: collab2 }, { src: collab3 }, { src: collab4 }]
    }, {
        heading: "Operations Platform",
        logo: lathransuiteLogo,
        subheading: "Streamline B2B Operations With All-In-One Integrated Operations Platform",
        description: "Centralize your business processes with secure access, collaborate in real-time, manage documents, capture feedback, oversee assets, and resolve issues—all in one place. ",
        languages: ["PHP-Laravel", "MERN", "WebRTC", "SSO", "SurveyJS"],
        images: [{ src: lathranSuite1 }, { src: lathransuite2 }, { src: lathransuite3 }, { src: lathransuite4 }]
    }, {
        heading: "Supply Chain Solution",
        logo: EzpaniLogo,
        subheading: "From Vendor Management To Warehousing, Inventory Management, Order Booking To Last Mile Delivery, All-In-One",
        description: "Appointment schedulers are business tools that allow clients to book, reschedule, and cancel appointments through a web interface.",
        languages: ["PHP-Laravel", "React JS", "MySQL", "HTML", "CSS", "Tailwind"],
        images: [{ src: EzpaniImage1 }, { src: EzpaniImage2 }, { src: EzpaniImage3 }, { src: EzpaniImage4 }]
    },
    {
        heading: "Scheduler",
        logo: SchedulerLogo,
        subheading: "Schedule Appointments Dynamically And Set Reminders",
        description: "Appointment schedulers are business tools that allow clients to book, reschedule, and cancel appointments through a web interface.",
        languages: ["PHP-Laravel", "React JS", "MySQL", "HTML", "CSS", "Tailwind"],
        images: [{ src: SchedulerImage1 }, { src: SchedulerImage2 }, { src: SchedulerImage3 }, { src: SchedulerImage4 }]
    },
    {
        heading: "Order Management System",
        logo: MomLogo,
        subheading: "OMS-Digital Order Booking Platform For Micro Businesses",
        description: "mediaOMS is an advanced advertising platform created for creating campaigns in the radio world.",
        languages: ["PHP-Laravel", "React JS", "MYSQL", "HTML", "CSS", "Tailwind"],
        images: [{ src: MOMSImage1 }, { src: MOMSImage2 }, { src: MOMSImage3 }, { src: MOMSImage4 }]
    },
    {
        heading: "Influencer Marketing Platform",
        logo: Flonzo,
        subheading: "Enabling Brands Match Their Product Campaign With The Right Influencers Through AI Based Logical Matchmaking",
        description: "Flonzo provides brands to streamline their influencer campaigns and drive more sales. Find the best influencers with the Flonzo calculator, including the database of vetted influencers.",
        languages: ["PHP-Laravel", "React JS", "MYSQL", "HTML", "CSS", "Tailwind"],
        images: [{ src: InfluencerImage1 }, { src: InfluencerImage2 }, { src: InfluencerImage3 }, { src: InfluencerImage4 }]
    },
    {
        heading: "Payment Gateway",
        logo: eztrialpaylogo,
        subheading: "Make Payments With A Piece Of Mind Through Our Secure And Customizable Payment Gateway Solution",
        description: "Allows direct compensation disbursements to trial participants",
        languages: ["PHP-Laravel", "React JS", "MYSQL", "HTML", "CSS", "Tailwind"],
        images: [{ src: PaymentGatewayImage1 }, { src: PaymentGatewayImage2 }, { src: PaymentGatewayImage3 }, { src: PaymentGatewayImage4 }]
    },
    {
        heading: "Survey Manager",
        logo: surveyMananegemnt,
        subheading: "ESurvey Gives Actionable Insight And Fresh Perspectives",
        description: "The system is designed to facilitate and build customizable surveys that collect information from a sample of individuals through their responses to questions enabling them to connect, discover and use feedback insights to make better, data-driven decisions that can drive growth and innovation. ",
        languages: ["PHP-Laravel", "React JS", "MYSQL", "HTML", "CSS", "Tailwind"],
        images: [{ src: SurveyManager1 }, { src: SurveyManager2 }, { src: SurveyManager3 }, { src: SurveyManager4 }]
    }
]